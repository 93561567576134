.nav {
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 50px;
  grid-auto-rows: minmax(150px, auto);
  margin: 0 auto;
  padding: 0 20px 10px 20px;
}

.dayName {
  font-size: 12px;
  text-transform: uppercase;
  color: #99a1a7;
  text-align: center;
  border-bottom: 1px solid rgba(166, 168, 179, 0.12);
  line-height: 50px;
  font-weight: 500;
}

.day {
  box-sizing: border-box;
  border-bottom: 1px solid rgba(166, 168, 179, 0.12);
  border-right: 1px solid rgba(166, 168, 179, 0.12);
  text-align: right;
  padding: 0 10px;
}

.disabled {
  color: rgba(152, 160, 166, 0.6);
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f9f9fa' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}

.day:nth-of-type(7n + 7) {
  border-right: 0;
}

.day:nth-of-type(n + 1):nth-of-type(-n + 7) {
  grid-row: 1;
}
.day:nth-of-type(n + 8):nth-of-type(-n + 14) {
  grid-row: 2;
}
.day:nth-of-type(n + 15):nth-of-type(-n + 21) {
  grid-row: 3;
}
.day:nth-of-type(n + 22):nth-of-type(-n + 28) {
  grid-row: 4;
}
.day:nth-of-type(n + 29):nth-of-type(-n + 35) {
  grid-row: 5;
}
.day:nth-of-type(n + 36):nth-of-type(-n + 42) {
  grid-row: 6;
}
.day:nth-of-type(n + 43):nth-of-type(-n + 49) {
  grid-row: 7;
}

.day:nth-of-type(7n + 1) {
  grid-column: 1/1;
}
.day:nth-of-type(7n + 2) {
  grid-column: 2/2;
}
.day:nth-of-type(7n + 3) {
  grid-column: 3/3;
}
.day:nth-of-type(7n + 4) {
  grid-column: 4/4;
}
.day:nth-of-type(7n + 5) {
  grid-column: 5/5;
}
.day:nth-of-type(7n + 6) {
  grid-column: 6/6;
}
.day:nth-of-type(7n + 7) {
  grid-column: 7/7;
}

.event {
  margin: 20px 0 10px;
  padding: 5px 10px;
  grid-column: 2 / span 4;
  grid-row: 4;
  background: rgba(253, 197, 208, 0.7);
  align-self: start; 
  position: relative;
  z-index: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.start {
  border-left: 3px solid #fa607e;
  margin-left: 5px;
}

.end { 
  border-radius: 0 3px 3px 0;
  margin-right: 5px;
}

.badge {
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  color: #fff;
  background-color: rgb(225, 0, 80);
  border-bottom-left-radius: 10px;
  height: 20px;
  min-width: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  padding-left: 5px;
  padding-right: 5px;
}


